<template>
  <div class="main-container">
    <h1 class="main-heading">{{ "Leaderbot" }}</h1>
    <div class="content-container">
      <div class="first-section">
        <div class="col">
          <div class="form-container">
            <p>{{ $t(`BOT_DECK_VALUES.STATUS`) }}</p>
            <select v-model.number="currentLeaderbot.status">
              <option v-for="status in botDeckConstants.STATUS" v-bind:key="status.value" :value="status.value">{{ status.text }}</option>
            </select>
          </div>
          <div class="form-container">
            <p>{{ "Name" }}</p>
            <input v-model="currentLeaderbot.name" />
          </div>
          <div class="form-container">
            <p>{{ "Trophy" }}</p>
            <input type="number" v-model="currentLeaderbot.trophy" />
          </div>
          <div class="form-container">
            <p>{{ "Highest Trophy" }}</p>
            <input type="number" v-model="currentLeaderbot.highest_trophy" />
          </div>
          <div class="form-container">
            <p>{{ "Avatar Index" }}</p>
            <input type="number" v-model="currentLeaderbot.avatar_index" />
          </div>
          <div class="form-container">
            <p>{{ "Best Wave" }}</p>
            <input type="number" v-model="currentLeaderbot.profile.best_wave" />
          </div>
          <div class="form-container">
            <p>{{ "Max Trophies (All time)" }}</p>
            <input type="number" v-model="currentLeaderbot.profile.maximum_trophies" />
          </div>
          <div class="form-container">
            <p>{{ "Victories" }}</p>
            <input type="number" @change="() => calculateWinRate()" v-model="currentLeaderbot.profile.victories" />
          </div>
          <div class="form-container">
            <p>{{ "Defeats" }}</p>
            <input type="number" @change="() => calculateWinRate()" v-model="currentLeaderbot.profile.defeats" />
          </div>
          <div class="form-container">
            <p>{{ "Win Rate" }}</p>
            <input disabled type="number" v-model="currentLeaderbot.profile.winrate" />
          </div>
          <div class="form-container">
            <p>{{ "Attacker Deck Reference" }}</p>
            <Multiselect 
              :multiple="false"
              :options="botDecks.map(x => { return { id: x.id, name: x.name } })"
              v-model="currentLeaderbot.attacker_deck_ref"
              label="name"
              track-by="name"
            />
          </div>
          <div class="form-container">
            <p>{{ "Counter Deck Reference" }}</p>
            <Multiselect 
              :multiple="false"
              :options="botDecks.map(x => { return { id: x.id, name: x.name } })"
              v-model="currentLeaderbot.counter_deck_ref"
              label="name"
              track-by="name"
            />
          </div>
        </div>
      </div>
      <div class="buttons-section">
        <Button 
          text="Sumbit"
          :onClick="() => submit()"
          type="success"
          :loading="isLoading"
        />
      </div>
    </div>
  </div>
</template>

<script>
import dispatchMap from "@/constants/dispatchMap";
import {
  computed,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  reactive,
  ref,
  watch,
} from "vue";
import { useStore } from "vuex";
import leaderbotSchema from "@/schemas/leaderbot";
import botDeckConstants from "@/constants/botDeckConstants";
import Multiselect from "vue-multiselect";
import Button from "../../components/common/Button.vue";
import { useRoute, useRouter } from "vue-router";

export default {
  name: "LeaderbotAdd",
  components: { Multiselect, Button },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const usedPages = ["leaderbots"];

    const isLoading = ref(false)

    const currentLeaderbot = reactive({ ...leaderbotSchema });

    const leaderbots = computed(
      () => store.getters["leaderbots/getLeaderbots"]
    );
    const botDecks = computed(() => store.getters["botDecks/getBotDecks"]);

    onMounted(() => {
      if (!route.query.id)
        store.dispatch("loader/loadingStatus", false)
      if (route.query.id && botDecks.value.length > 0) {
        store.dispatch("loader/loadingStatus", false)
        const editingLeaderbot = JSON.parse(
          JSON.stringify(leaderbots.value.find((x) => x.id === route.query.id))
        );
        Object.keys(editingLeaderbot).forEach((key) => {
          currentLeaderbot[key] = editingLeaderbot[key];
        });
      }
    });

    watch(
      () => store.getters["leaderbots/getLeaderbots"],
      (leaderbots) => {
        if (route.query.id && leaderbots.length > 0) {
          store.dispatch("loader/loadingStatus", false)
          const editingLeaderbot = JSON.parse(
            JSON.stringify(leaderbots.find((x) => x.id === route.query.id))
          );
          Object.keys(editingLeaderbot).forEach((key) => {
            currentLeaderbot[key] = editingLeaderbot[key];
          });
        }
      }
    );

    onBeforeUnmount(() => store.dispatch("loader/loadingStatus", true));

    onBeforeMount(() => {
      usedPages.forEach((page) => {
        dispatchMap[page].forEach((dispatchStr) => {
          store.dispatch(dispatchStr);
        });
      });
    });

    const calculateWinRate = () => {
      if (!Number.isInteger(currentLeaderbot.profile.victories)||!Number.isInteger(currentLeaderbot.profile.defeats))
        return
      currentLeaderbot.profile.winrate=Math.round(((currentLeaderbot.profile.victories)/(currentLeaderbot.profile.victories+currentLeaderbot.profile.defeats)*1000))/10
    }

    const submit = () => {
      isLoading.value = true;

      if(route.query.id) {
        store.dispatch('leaderbots/updateLeaderbot', { id: route.query.id, data: currentLeaderbot }).then(() => {
          router.push({ name: "Leaderbots" })
        }).finally(() => {
          isLoading.value = false
        })
      } else {
        store.dispatch('leaderbots/addLeaderbot', { data: currentLeaderbot }).then(() => {
          router.push({ name: "Leaderbots" })
        }).finally(() => {
          isLoading.value = false
        })
      }
    };

    const appConfig = computed(() => store.getters["auth/getAppConfig"]);
    const env = appConfig.value.appEnv || "dev";

    return {
      currentLeaderbot,
      botDecks,
      env,
      submit,
      calculateWinRate,
      isLoading,
      botDeckConstants
    };
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style scoped>
.main-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 30px;
}

.content-container {
  width: 100%;
}
.first-section {
  padding: 10px 0;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: start;
}

.form-container-inline {
  display: flex;
  align-items: center;
}

.form-container-inline p {
  margin: 15px 15px 15px 0;
}

.form-container-inline input {
  width: auto;
  margin: 0 10px;
}

p {
  margin-bottom: 0;
  font-size: 0.875rem;
}

input,
textarea,
select {
  width: calc(100% - 25px);
  padding: 5px 0;
}

.col-title {
  font-weight: 600;
  font-size: 1.125rem;
}

.col-subtitle {
  margin: 15px 0;
  font-weight: 600;
}

select {
  margin: 5px 0;
}

.buttons-section {
  margin-top: 50px;
  display: flex;
}
</style>