export default {
    name: "",
    trophy: 4000,
    highest_trophy: 4000,
    profile: {
        victories: 0,
        defeats: 0,
        winrate: 0,
        best_wave: 0,
        maximum_trophies: 0
    },
    avatar_index: 0,
    attacker_deck_ref: undefined,
    counter_deck_ref: undefined,
    status: 0,
    game_status: 0
}